body {
  font-family: Arial, sans-serif;
  margin: 0;
  padding: 0;
  background-color: #f2f2f2;
}

.App {
  text-align: center;
  padding: 20px;
}

.App-header {
  background-color: #333;
  color: #fff;
  padding: 20px;
}

.Features-section {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  padding: 50px 0;
}

.Feature {
  /* text-align: center; */
  flex: 1;
  margin: 0 20px;
}

.Feature i {
  font-size: 36px;
  margin-bottom: 10px;
}

.App-footer {
  padding: 20px;
}